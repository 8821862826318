<template>
    <div>
      <b-modal ok-only id="inactivity" class="btn-color" title="Inactivity Warning">
        <p class="my-4">You will be logged out soon if not active</p>
        <template #modal-footer>
          <button v-b-modal.modal-close_visit @click="$bvModal.hide('inactivity')" class="btn btn-danger btn-md m-1">Close</button>
        </template>
      </b-modal>
        <slot></slot>
    </div>
</template>

<script>
import LogoutUser from "@/services/LogoutUser"

export default {
    name: "AutoLogout",
    data() {
        return {
            activityTimer: null,
            activityTimerThrottler: null,
            warningTimer: null
        }
    },
    methods: {
        logout() {
            LogoutUser.logout()
            .then((resp) => {
                if (resp.status === 200) {
                this.$router.push("/").catch(error => {
                    if (error.name != "NavigationDuplicated") {
                        throw error;
                    }
                })
                } else {
                throw new Error("Error")
                }
            })
            .catch( error => {
                console.log(error)
            })
        },
        resetUserActivityTimeout() {
            clearTimeout(this.activityTimer);
            clearTimeout(this.warningTimer);
            this.warningTimer = setTimeout(() => {
                this.$bvModal.show('inactivity')
            }, 900000);
            this.activityTimer = setTimeout(() => {
                this.logout()
            }, 1200000);
        },
        userActivityThrottler() {
            if (!this.activityTimerThrottler) {
                this.activityTimerThrottler = setTimeout(() => {
                    this.resetUserActivityTimeout()
                    clearTimeout(this.activityTimerThrottler)
                    this.activityTimerThrottler = null
                }, 120000);
            }
        },
        activateActivityTracker() {
            this.userActivityThrottler()
            window.addEventListener("mousemove", this.userActivityThrottler)
            window.addEventListener("mousemove", this.userActivityThrottler)
            window.addEventListener("mousemove", this.userActivityThrottler)
            window.addEventListener("scroll", this.userActivityThrottler)
            window.addEventListener("keydown", this.userActivityThrottler)
            window.addEventListener("resize", this.userActivityThrottler)
        }
    },
    mounted() {
        this.activateActivityTracker()
    },
    beforeDestroy() {
        window.removeEventListener("mousemove", this.userActivityThrottler)
        window.removeEventListener("scroll", this.userActivityThrottler)
        window.removeEventListener("keydown", this.userActivityThrottler)
        window.removeEventListener("resize", this.userActivityThrottler)
        clearTimeout(this.activityTimer)
        clearTimeout(this.warningTimer)
        clearTimeout(this.activityTimerThrottler)
        this.activityTimer = null
        this.warningTimer = null
        this.activityTimerThrottler = null
    }
}
</script>

<style scoped></style>
