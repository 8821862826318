<template>
<auto-logout>
  <v-data-table
    :headers="headers"
    :search="search"
    :items="agents"
    sort-by="UpdatedOn"
    :single-expand="singleExpand"
    :expanded.sync="expanded"
    item-key="RecruitedAgentId"
    show-expand
    :loading="tableLoading"
    loading-text="Loading..."
    class="elevation-1"
  >
    <template v-slot:body.prepend> <!-- eslint-disable-line  -->
      <tr>
          <td>
            <v-text-field v-model="filter.firstName" type="text" label="First Name"></v-text-field>
          </td>
          <td>
            <v-text-field v-model="filter.lastName" type="text" label="Last Name"></v-text-field>
          </td>
          <td>
            <v-text-field v-model="filter.agency" type="text" label="Agency"></v-text-field>
          </td>
          <td></td>
          <td></td>
          <td>
              <v-select
                :items="statusIdMapArr"
                v-model="filter.status"
                item-text="status"
                item-value="statusId"
                clearable
                label="Status"
              ></v-select>
            <!-- <v-text-field v-model="filter.status" type="text" label="Status"></v-text-field> -->
          </td>
          <td></td>
          <td></td>
          <td></td>
      </tr>
    </template>
    <template v-slot:[`item.DateTime`]="{ item }">
      {{ formatDateTime(item.DateTime) }}
    </template>
    <template v-slot:[`item.UpdatedOn`]="{ item }">
      {{ formatDateTime(item.UpdatedOn) }}
    </template>
    <template v-slot:[`item.AgentDOB`]="{ item }">
      {{ isValidDate(item.AgentDOB) ? formatDateDefault(item.AgentDOB) : '' }}
    </template>
    <template v-slot:[`item.AgentEmail`]="{ item }">
      <a
        target="_blank"
        :href="`mailto:${item.AgentEmail}?subject=SOME SUBJECT LINE&body=SOME BODY TEXT`"
      >
        {{ item.AgentEmail }}
      </a>
    </template>
    >
    <template v-slot:[`item.RecruitmentStatusId`]="{ item }">
      <v-chip
        :color="getColor(item.RecruitmentStatusId)"
        dark
      >
      {{ statusIdToText(item.RecruitmentStatusId) }}
      </v-chip>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <ul class="details-ul light-theme">
          <li><b>SSN: </b>{{ item.AgentSSN }}</li>
          <li><b>Date of Birth: </b>{{ isValidDate(item.AgentDOB) ? formatShortDateMDY(item.AgentDOB) : '' }}</li>
          <li><b>Agent NPN: </b>{{ item.AgentNPN }}</li>
          <li><b>Agency NPN: </b>{{ item.AgencyNPN }}</li>
        </ul>
      </td>
    </template>
    <template v-slot:top>
      <v-toolbar flat class="mt-1 mb-4">
        <!-- <v-toolbar-title> <a @click="recruiterDashboard" id="dashboard-link" href="">Recruiter</a> </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-toolbar-title>Agent</v-toolbar-title> -->
        <v-toolbar-title><v-btn text left color="primary" class="p-1" @click="recruiterDashboard">Recruiter</v-btn></v-toolbar-title>
        <v-divider class="mx-3" inset vertical></v-divider>
        <v-toolbar-title> <v-btn text disabled color="normal" class="p-1" @click="agentDashboard">Agent</v-btn> </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="600px">
          <v-card>
           <validation-observer
              ref="observer"
              v-slot="{ invalid }"
           >
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <validation-provider
                      v-slot="{ errors }"
                      name="First name"
                      :rules="{max: 50, regex: /^(?=.{1,50}$)[a-zA-Z]+(?:[-'\s][a-zA-Z]+)*$/}"
                    >
                    <v-text-field
                      v-model="editedItem.AgentFirstName"
                      label="First Name"
                      maxlength="50"
                      :counter="50"
                      :error-messages="errors"
                    ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <validation-provider
                      v-slot="{ errors }"
                      name="last name"
                      :rules="{max: 50, regex: /^(?=.{1,50}$)[a-zA-Z]+(?:[-'\s][a-zA-Z]+)*$/}"
                    >
                    <v-text-field
                      v-model="editedItem.AgentLastName"
                      label="Last Name"
                      maxlength="50"
                      :counter="50"
                      :error-messages="errors"
                    ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <validation-provider
                      v-slot="{ errors }"
                      name="agent email"
                      :rules="{ email: true, max: 250 }"
                    >
                    <v-text-field
                      v-model="editedItem.AgentEmail"
                      label="Agent Email"
                      maxlength="250"
                      :counter="250"
                      :error-messages="errors"
                    ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <validation-provider
                      v-slot="{ errors }"
                      name="agent npn"
                      :rules="{ numeric: true, alpha: false, max: 10 }"
                    >
                    <v-text-field
                      v-model="editedItem.AgentNPN"
                      label="Agent NPN"
                      :error-messages="errors"
                      maxlength="10"
                      :counter="10"
                    ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <validation-provider
                      v-slot="{ errors }"
                      name="agency name"
                      :rules="{max: 100, regex: /^(?=.{1,100}$)[a-zA-Z]+(?!.*[ ]{2})(?:[\&-'\s]+[a-zA-Z]+)*$/}"
                    >
                    <v-text-field
                      v-model="editedItem.AgencyName"
                      label="Agency Name"
                      maxlength="100"
                      :counter="100"
                      :error-messages="errors"
                    ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <validation-provider
                      v-slot="{ errors }"
                      name="agency npn"
                      :rules="{ numeric: true, alpha: false, max: 10 }"
                    >
                    <v-text-field
                      v-model="editedItem.AgencyNPN"
                      label="Agency NPN"
                      maxlength="10"
                      :counter="10"
                      :error-messages="errors"
                    ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <validation-provider
                      v-slot="{ errors }"
                      name="social security number"
                      :rules="{ min: 11, regex: /^[0-9-]*$/, max: 11 }"
                    >
                    <v-text-field
                      v-model="editedItem.AgentSSN"
                      label="SSN"
                      placeholder="###-##-####"
                      v-mask="'###-##-####'"
                      :error-messages="errors"
                    ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <validation-provider
                      v-slot="{ errors }"
                      name="agent dob"
                      rules=""
                    >
                    <v-text-field
                      v-model="editedItem.AgentDOB"
                      type="date"
                      label="Agent DOB"
                      max="9999-12-31"
                      :error-messages="errors"
                    ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-select
                      :items="statusIdMapArr"
                      v-model="editedItem.RecruitmentStatusId"
                      item-text="status"
                      item-value="statusId"
                      label="Status"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
              <v-btn color="blue darken-1" text :disabled="invalid" @click="editedIndex === -1 ? postData() : updateData()">
                Save
              </v-btn>
            </v-card-actions>
           </validation-observer>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Are you sure you want to delete this item?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteData"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
       <template v-if="nonCSHFile">
        <v-file-input
          accept=".csv "
          label="Non-CSH upload (.csv)"
          class="mt-1"
          style="max-width:250px;"
          :loading="fileLoading"
          @change="handleFileUpload"
          v-model="file"
          truncate-length="12"
          dense
          hide-details
        ></v-file-input>
        </template>
       <template v-else>
        <v-file-input
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          label="CSH upload (.xlsx)"
          class="mt-1"
          style="max-width:250px;"
          :loading="fileLoading"
          @change="handleFileUpload"
          v-model="file"
          truncate-length="12"
          dense
          hide-details
        ></v-file-input>
        </template>
        <template style="margin-bottom: 0px;">
            <v-switch
              class="mt-1 ml-1"
              v-b-tooltip.hover.bottom="'Select if non-CSH upload'"
              style="height: 30px;"
              v-model="nonCSHFile"
              disabled
            ></v-switch>
        </template>
        <v-btn color="error" dark class="mb-2 ml-4" @click="logout">
              Log Out
        </v-btn>
        <v-dialog
          v-model="alertDialog"
          max-width="290"
        >
          <v-card>
            <v-card-title class="text-h5">
              Success
            </v-card-title>
            <v-card-text>
              Record(s) successfully {{alertDialogText}}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="alertDialog = false"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="errorDialog"
          max-width="290"
        >
          <v-card>
            <v-card-title class="text-h5">
              Error
            </v-card-title>
            <v-card-text>
              {{errorDialogText}}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="errorDialog = false"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="getAllData"> Refresh </v-btn>
    </template>
    <template v-slot:body.append="{ headers }"> <!-- eslint-disable-line  -->
    <tr>
      <td :colspan="headers.length" align="center">
      <apexchart width="400" type="donut" :options="pieOptions" :series="pieSeries"></apexchart>
      </td>
    </tr>
    </template>
  </v-data-table>
  </auto-logout>
</template>

<script>
import AdminDashboardAgentData from "@/services/AdminDashboardAgentData"
import LogoutUser from "@/services/LogoutUser"
import AuthCheck from "@/services/AuthCheck"
import { STATUS_ID_MAP, STATUS_ID_MAP_ARR } from "@/helpers/statusMaps"
import { CSH_FILE_COLUMNS  } from "@/helpers/fileUploadFields"
// import axios from 'axios'
import AutoLogout from "./AutoLogout.vue"
import VueApexCharts from "vue-apexcharts"
import moment from 'moment/src/moment'

export default {
  name: "AdminDashboard",
  components: {
    AutoLogout,
    apexchart: VueApexCharts
  },
  data() {
    return {
      pieOptions: {
        legend: {
          show: true,
          position: 'bottom'
        },
        labels: []
      },
      pieSeries: [],
      statusIdMap: STATUS_ID_MAP,
      statusIdMapArr: STATUS_ID_MAP_ARR,
      expanded: [],
      search: '',
      filter : {
        firstName: '',
        lastName: '',
        agency: '',
        status: '',
      },
      singleExpand: true,
      alertDialog: false,
      alertDialogText: '',
      errorDialog: false,
      errorDialogText: '',
      dialogDelete: false,
      dialog: false,
      menu: true,
      selectedDate: null,
      agents: [],
      editedIndex: -1,
      editedItem: {
        AgentFirstName: "",
        AgentLastName: "",
        AgentDOB: null,
        AgentSSN: "",
        AgentNPN: "",
        AgentEmail: "",
        AgencyName: "",
        AgencyNPN: "",
        RecruitmentStatusId: "",
      },
      defaultItem: {
        AgentFirstName: "",
        AgentLastName: "",
        AgentDOB: null,
        AgentSSN: "",
        AgentNPN: "",
        AgentEmail: "",
        AgencyName: "",
        AgencyNPN: "",
        RecruitmentStatusId: "",
      },
      required_cols: CSH_FILE_COLUMNS,
      nonCSHFile: '',
      file: null,
      content: [],
      parsed: false,
      fileLoading: false,
      tableLoading: false,
      activityTimer: null,
      activityTimerThrottler: null,
    };
  },
  methods: {
    async authCheck() {
      try {
        let resp = await AuthCheck.check()
        if (resp.status === 200) {
          console.log("session valid")
        }
      }
      catch (error) {
        if (error.response.status == 401) { 
          console.log("session timeout")
          this.logout()
        }

      }
    },
    getAllData() {
      this.tableLoading = true
      AdminDashboardAgentData.getAllAgentData()
        .then((resp) => {
          if (resp.status === 200) {
          this.agents = resp.data.agents;
          this.agents = this.agents.map((o) => ({
            ...o,
            AgentDOB: /*formatDateDefault(o.AgentDOB)*/ this.isValidDate(o.AgentDOB) ? moment(o.AgentDOB).format("yyyy-MM-DD") : '',
          }));
          let pieChartData = resp.data.pieChart
          pieChartData.forEach(pie => {
            this.pieOptions.labels.push(pie.Status);
            this.pieSeries.push(pie.StatusCount);
          
          this.tableLoading = false
          });

          } else {
            throw new Error("Error");
          }
        })
        .catch((error) => {
          this.tableLoading = false
          if (error.response.status == 401) {
            this.$router.push("/")
          }
          console.log(error);
        });
    },
    updateData() {
      AdminDashboardAgentData.updateAgentData(this.editedItem)
        .then((resp) => {
          this.alertDialogText = 'updated'
          this.alertDialog = true
          if (resp.status === 200) {
            this.save();
          } else {
            throw new Error("Error");
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$router.push("/")
          }
          else if (error.response.status == 409) {
            alert("Error: record contains duplicate data (ex. email, agent npn, or other)")
          }
          else {
              alert("Error: record could not be updated")
          }
          console.log(error.response.status);
          this.close();
        });
    },
    deleteData() {
      AdminDashboardAgentData.deleteAgentData(this.editedItem)
        .then((resp) => {
          this.alertDialogText = 'deleted'
          this.alertDialog = true
          if (resp.status === 200) {
            this.deleteItemConfirm();
          } else {
            throw new Error("Error");
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$router.push("/")
          }
          else {
              alert("Error: record could not be deleted")
          }
          console.log(error.response.status);
          this.closeDelete();
        });
    },
    async uploadData(formData) {
      try {
        const resp = await AdminDashboardAgentData.uploadCshAgentData(formData) 
        // const resp = await axios.post('http://127.0.0.1:5000/api/upload/csh-agents', formData, {
        //     headers: {
        //       'Content-Type': 'multipart/form-data'
        //     }
        //   });
        // const resp = await axios.post('https://q8m5wnbuj1.execute-api.us-east-2.amazonaws.com/prod/api/upload/csh-agents', formData, {
        //     headers: {
        //       'Content-Type': 'multipart/form-data'
        //     }
        //   });
        if (resp.status === 200) {
          this.fileLoading = false;
          this.alertDialogText = 'uploaded';
          this.alertDialog = true;
        }
      }
      catch (error) {
        if (error.response.data.error) {
            this.fileLoading = false;
            this.errorDialogText = error.response.data.error;
            if(!this.errorDialogText) {
              this.errorDialogText = "Could not connect to server";
            }
            this.errorDialog = true;
        }
        console.log(error) 
      }
     
    },
    async handleFileUpload(){
      if(this.file) {
        this.fileLoading = true;
        try {
          let formData = new FormData();
          formData.append("file", this.file);
          await this.authCheck()
          await this.uploadData(formData);
        }
        catch (error) {
          this.fileLoading = false
          console.log(error)
        }
        finally {
          this.file = null
        }
      }
    },
    statusIdToText(statusId) {
      let obj = this.statusIdMapArr.find((o) => o.statusId === statusId);
      return obj.status;
    },
    // statusTextToId(statusText) {
    //   let obj = this.statusIdMapArr
    //   return Object.keys(obj).find(key => obj[key] === value);
    // },
    clearSelectStatus() {
      this.filter.status = ''
    },
    getColor (statusId) {
        if (statusId === 1) return 'primary'
        else if (statusId === 18) return 'green'
        else if (statusId === 19) return 'red'
        else if (!statusId) return 'default'
        else return "orange"
    },
    isValidDate(value) {
      let date = moment(value)
      return date.isValid()
    },
    formatDateDefault(value) {
      return moment(value).format("yyyy-MM-DD")
    },

    formatShortDateMDY(value) {
      return moment(value).format("MM/DD/yyyy")
    },

    formatDateTime(value) {
      const utc_datestring = moment.utc(value)
      return utc_datestring.local().format("MM/DD/YYYY HH:mm:ss")
    },

    editItem(item) {
      this.editedIndex = this.agents.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.agents.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.agents.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.agents[this.editedIndex], this.editedItem);
      } else {
        this.agents.push(this.editedItem);
      }
      this.close();
    },
    logout() {
      LogoutUser.logout()
      .then((resp) => {
        if (resp.status === 200) {
          this.$router.push("/").catch(error => {
            if (error.name != "NavigationDuplicated") {
                throw error;
            }
          })
        } else {
          throw new Error("Error")
        }
      })
      .catch( error => {
          console.log(error)
      })
    },
    recruiterDashboard() {
      this.$router.push('/dashboard')
    },
  },
  created() {
    this.getAllData()
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Agent" : "Edit Agent Record";
    },
     headers() {
      return [
        { value: "AgentFirstName", text: "First Name", sortable: true, filter: f => { return ( f + '' ).toLowerCase().includes( this.filter.firstName.toLowerCase() ) } },
        { value: "AgentLastName", text: "Last Name", sortable: true, filter: f => { return ( f + '' ).toLowerCase().includes( this.filter.lastName.toLowerCase() ) } },
        { value: "AgencyName", text: "Agency", sortable: true, filter: f => { return ( f + '' ).toLowerCase().includes( this.filter.agency.toLowerCase() ) } },
        { value: "AgentEmail", text: "Agent Email", sortable: true },
        { value: "DateTime", text: "Added On", sortable: true },
        { value: "RecruitmentStatusId", text: "Status", sortable: true,  filter: value => { if (!this.filter.status) return true; return value == parseInt(this.filter.status); }},
        { value: "UpdatedOn", text: "Last Status Update", sortable: true },
        { text: "", value: "data-table-expand" },
        { value: "actions", text: "Actions", sortable: false },
      ]
     }
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
};
</script>

<style scoped>
.details-ul {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding-left: 12px;
}

#dashboard-link {
  text-decoration: none;
  color: #1976d2;
}
#dashboard-link:hover {
  filter: brightness(115%);
}

.light-theme {
  color: rgba(0, 0, 0, 0.6);
}

input[type="date"]::-webkit-calendar-picker-indicator {
  margin-left: -25px !important;
}
</style>
