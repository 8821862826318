export const STATUS_ID_MAP =  {
    1: "Pending",
    2: "Created - New",
    3: "Unsubmitted - Additional Info",
    4: "Unsubmitted - Banking Info",
    5: "Unsubmitted - Contact Info",
    6: "Unsubmitted - Contract Info",
    7: "Unsubmitted - Document Info",
    8: "Unsubmitted - License Info",
    9: "Unsubmitted - Payment Info",
    10: "Unsubmitted - Submit",
    11: "Unsubmitted - Training",
    12: "Failed Training",
    13: "Reset Training",
    14: "Cancelled",
    15: "Aborted By Rep",
    16: "Correction Requested",
    17: "Submitted",
    18: "Approved",
    19: "Denied"
  }

export const STATUS_ID_MAP_ARR = [
    { statusId: 1, status: "Pending" },
    { statusId: 2, status: "Created - New" },
    { statusId: 3, status: "Unsubmitted - Additional Info" },
    { statusId: 4, status: "Unsubmitted - Banking Info" },
    { statusId: 5, status: "Unsubmitted - Contact Info" },
    { statusId: 6, status: "Unsubmitted - Contract Info" },
    { statusId: 7, status: "Unsubmitted - Document Info" },
    { statusId: 8, status: "Unsubmitted - License Info" },
    { statusId: 9, status: "Unsubmitted - Payment Info" },
    { statusId: 10, status: "Unsubmitted - Submit" },
    { statusId: 11, status: "Unsubmitted - Training" },
    { statusId: 12, status: "Failed Training" },
    { statusId: 13, status: "Reset Training" },
    { statusId: 14, status: "Cancelled" },
    { statusId: 15, status: "Aborted By Rep" },
    { statusId: 16, status: "Correction Requested" },
    { statusId: 17, status: "Submitted" },
    { statusId: 18, status: "Approved" },
    { statusId: 19, status: "Denied" }
  ]
