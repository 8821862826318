export const GENERIC_FILE_COLUMNS =  [
    'AgencyNPN',
    'AgentFirstName',
    'AgentLastName',
    'AgentNPN',
    'AgentDOB',
    'AgentSSN',
    'AgentEmail'
]

export const CSH_FILE_COLUMNS =  [
    'AgentFirstName',
    'AgentLastName',
    'AgentEmail',
    'AgentNPN',
    'AgencyName',
    'RecruitmentStatusId'
]
