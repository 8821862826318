<template>
<auto-logout>
  <v-data-table
    :headers="headers"
    :items="agents"
    sort-by="DateTime"
    :single-expand="singleExpand"
    :expanded.sync="expanded"
    item-key="RecruitedAgentId"
    show-expand
    :loading="tableLoading"
    loading-text="Loading..."
    class="elevation-1"
  >
    <template v-slot:[`item.DateTime`]="{ item }">
      {{ formatDateTime(item.DateTime) }}
    </template>
    <template v-slot:[`item.AgentEmail`]="{ item }">
      <a
        target="_blank"
        :href="`mailto:${item.AgentEmail}?subject=SOME SUBJECT LINE&body=SOME BODY TEXT`"
      >
        {{ item.AgentEmail }}
      </a>
      <v-icon v-b-tooltip.hover.right="'Copy QR code'" v-b-tooltip.focus.right="'Copied!'" class="ml-2" small @click="getQrBlob((item.RecruitedAgentId))">
        mdi-qrcode-plus
      </v-icon>
    </template>
    >
    <template v-slot:[`item.RecruitmentStatusId`]="{ item }">
      {{ statusIdToText(item.RecruitmentStatusId) }}
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <ul class="details-ul light-theme">
          <li><b>Agency NPN: </b>{{ item.AgencyNPN }}</li>
        </ul>
      </td>
    </template>
    <template v-slot:top> 
      <v-toolbar flat class="mt-1 mb-4">
        <!-- <v-toolbar-title>Recruiter</v-toolbar-title> -->
        <v-toolbar-title><v-btn text left color="normal" class="p-1" disabled >Recruiter</v-btn></v-toolbar-title>
        <v-divider class="mx-3" inset vertical></v-divider>
        <!-- <v-toolbar-title> <a @click="agentDashboard" href="" id="dashboard-link">Agent</a> </v-toolbar-title> -->
        <v-toolbar-title> <v-btn text  color="primary" class="p-1" @click="agentDashboard">Agent</v-btn> </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="" v-bind="attrs" v-on="on">
              New Recruiter
            </v-btn>
          </template>
          <v-card>
           <validation-observer
              ref="observer"
              v-slot="{ invalid }"
           >
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <validation-provider
                      v-slot="{ errors }"
                      name="First name"
                      :rules="{max: 50, regex: /^(?=.{1,50}$)[a-zA-Z]+(?:[-'\s][a-zA-Z]+)*$/}"
                    >
                    <v-text-field
                      v-model="editedItem.AgentFirstName"
                      label="First Name"
                      maxlength="50"
                      :counter="50"
                      :error-messages="errors"
                    ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <validation-provider
                      v-slot="{ errors }"
                      name="last name"
                      :rules="{max: 50, regex: /^(?=.{1,50}$)[a-zA-Z]+(?:[-'\s][a-zA-Z]+)*$/}"
                    >
                    <v-text-field
                      v-model="editedItem.AgentLastName"
                      label="Last Name"
                      maxlength="50"
                      :counter="50"
                      :error-messages="errors"
                    ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <validation-provider
                      v-slot="{ errors }"
                      name="agent email"
                      :rules="{ email: true, max: 250 }"
                    >
                    <v-text-field
                      v-model="editedItem.AgentEmail"
                      label="Agent Email"
                      maxlength="250"
                      :counter="250"
                      :error-messages="errors"
                    ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <validation-provider
                      v-slot="{ errors }"
                      name="agency name"
                      :rules="{max: 100, regex: /^(?=.{1,100}$)[a-zA-Z]+(?!.*[ ]{2})(?:[\&-'\s]+[a-zA-Z]+)*$/}"
                    >
                    <v-text-field
                      v-model="editedItem.AgencyName"
                      label="Agency Name"
                      maxlength="100"
                      :counter="100"
                      :error-messages="errors"
                    ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <validation-provider
                      v-slot="{ errors }"
                      name="agency npn"
                      :rules="{ numeric: true, alpha: false, max: 10 }"
                    >
                    <v-text-field
                      v-model="editedItem.AgencyNPN"
                      label="Agency NPN"
                      maxlength="10"
                      :counter="10"
                      :error-messages="errors"
                    ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col v-if="showStatus" cols="12" sm="6" md="4">
                    <v-select
                      :items="statusIdMapArr"
                      v-model="editedItem.RecruitmentStatusId"
                      item-text="status"
                      item-value="statusId"
                      label="Status"
                    ></v-select>
                  </v-col>
                  <v-col v-else cols="12" sm="6" md="4">
                    <v-select
                      :items="statusIdMapArrDefault"
                      v-model="editedItem.RecruitmentStatusId"
                      item-text="status"
                      item-value="statusId"
                      label="Status"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
              <v-btn color="blue darken-1" text :disabled="invalid" @click="editedIndex === -1 ? postData() : updateData()">
                Save
              </v-btn>
            </v-card-actions>
           </validation-observer>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Are you sure you want to delete this item?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteData"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-btn color="error" dark class=" ml-2" @click="logout">
              Log Out
        </v-btn>
        <v-dialog
          v-model="alertDialog"
          max-width="290"
        >
          <v-card>
            <v-card-title class="text-h5">
              Success
            </v-card-title>
            <v-card-text>
              Record successfully {{alertDialogText}}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="alertDialog = false"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="getAllData"> Refresh </v-btn>
    </template>
  </v-data-table>
  </auto-logout>
</template>

<script>
import AdminDashboardRecruiterData from "@/services/AdminDashboardRecruiterData"
import LogoutUser from "@/services/LogoutUser"
import AuthCheck from "@/services/AuthCheck"
import { STATUS_ID_MAP, STATUS_ID_MAP_ARR } from "@/helpers/statusMaps"
import AutoLogout from "./AutoLogout.vue"
import dataURItoBlob from "@/helpers/dataURItoBlob"
import moment from 'moment/src/moment'
import qr from "qr-image"

export default {
  name: "AdminDashboard",
  components: {
    AutoLogout,
  },
  data() {
    return {
      statusIdMap: STATUS_ID_MAP,
      statusIdMapArr: STATUS_ID_MAP_ARR,
      statusIdMapArrDefault: [
        { statusId: 1, status: "Pending" }
      ],
      expanded: [],
      singleExpand: true,
      alertDialog: false,
      alertDialogText: '',
      dialog: false,
      showStatus: false,
      dialogDelete: false,
      headers: [
        { value: "AgentFirstName", text: "First Name", sortable: true },
        { value: "AgentLastName", text: "Last Name", sortable: true },
        { value: "AgencyName", text: "Agency", sortable: true },
        { value: "AgentEmail", text: "Agent Email", sortable: true },
        { value: "RecruitmentStatusId", text: "Status", sortable: true },
        { value: "DateTime", text: "Added On", sortable: true },
        { text: "", value: "data-table-expand" },
        { value: "actions", text: "Actions", sortable: false },
      ],
      agents: [],
      editedIndex: -1,
      editedItem: {
        AgentFirstName: "",
        AgentLastName: "",
        AgentEmail: "",
        AgencyName: "",
        AgencyNPN: "",
        RecruitmentStatusId: 1,
      },
      defaultItem: {
        AgentFirstName: "",
        AgentLastName: "",
        AgentEmail: "",
        AgencyName: "",
        AgencyNPN: "",
        RecruitmentStatusId: 1,
      },
      menu: true,
      selectedDate: null,
      tableLoading: false,
      activityTimer: null,
      activityTimerThrottler: null
    };
  },
  methods: {
    async authCheck() {
      try {
        let resp = await AuthCheck.check()
        if (resp.status === 200) {
          console.log("session valid")
        }
      }
      catch (error) {
        if (error.response.status == 401) { 
          console.log("session timeout")
          this.logout()
        }

      }
    },
    getAllData() {
      this.tableLoading = true
      AdminDashboardRecruiterData.getAllRecruiterData()
        .then((resp) => {
          if (resp.status === 200) {
          this.agents = resp.data.result;
          this.tableLoading = false
          } else {
            throw new Error("Error");
          }
        })
        .catch((error) => {
          this.tableLoading = false
          if (error.response.status == 401) {
            this.$router.push("/")
          }
          console.log(error);
        });
    },
    updateData() {
      AdminDashboardRecruiterData.updateRecruiterData(this.editedItem)
        .then((resp) => {
          this.alertDialogText = 'updated'
          this.alertDialog = true
          if (resp.status === 200) {
            this.save();
          } else {
            throw new Error("Error");
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$router.push("/")
          }
          else if (error.response.status == 409) {
            alert("Error: record contains duplicate data (ex. email, agent npn, or other)")
          }
          else {
              alert("Error: record could not be updated")
          }
          console.log(error.response.status);
          this.close();
        });
    },
    postData() {
      AdminDashboardRecruiterData.postRecruiterData(this.editedItem)
        .then((resp) => {
          this.alertDialogText = 'added'
          this.alertDialog = true
          if (resp.status === 200) {
            this.save();
          } else {
            throw new Error("Error");
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$router.push("/")
          }
          else if (error.response.status == 409) {
            alert("Error: record contains duplicate data (ex. email, agent npn, or other)")
          }
          else {
              alert("Error: record could not be added")
          }
          console.log(error.response.status);
          this.close();
        });
    },
    deleteData() {
      AdminDashboardRecruiterData.deleteRecruiterData(this.editedItem)
        .then((resp) => {
          this.alertDialogText = 'deleted'
          this.alertDialog = true
          if (resp.status === 200) {
            this.deleteItemConfirm();
          } else {
            throw new Error("Error");
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.$router.push("/")
          }
          else {
              alert("Error: record could not be deleted")
          }
          console.log(error.response.status);
          this.closeDelete();
        });
    },
    getQrBlob(recruiterId) {
      const qrUrl = `${this.$store.state.baseUrl}/onboard?qr_code=true&recruiter_id=${recruiterId}`;
      let qr_svg = qr.imageSync(qrUrl, { type: "png" });
      let qr_str = "data:image/png;base64," + qr_svg.toString("base64");
      let blob = dataURItoBlob.convert(qr_str);
      try {
        navigator.clipboard.write([
          new window.ClipboardItem({
            "image/png": blob, // change image type accordingly
          }),
        ]);
      } catch (error) {
        console.error(error);
      }
    },
    statusIdToText(statusId) {
      let obj = this.statusIdMapArr.find((o) => o.statusId === statusId);
      return obj.status;
    },

    formatDateDefault(value) {
      return moment(value).format("yyyy-MM-DD")
    },

    formatShortDateMDY(value) {
      return moment(value).format("MM/DD/yyyy")
    },

    formatDateTime(value) {
      const utc_datestring = moment.utc(value)
      return utc_datestring.local().format("MM/DD/YYYY HH:mm:ss")
    },

    editItem(item) {
      this.editedIndex = this.agents.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
      this.showStatus = true;
    },

    deleteItem(item) {
      this.editedIndex = this.agents.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.agents.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.showStatus = false;
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.agents[this.editedIndex], this.editedItem);
      } else {
        this.agents.push(this.editedItem);
      }
      this.close();
    },
    logout() {
      LogoutUser.logout()
      .then((resp) => {
        if (resp.status === 200) {
          this.$router.push("/").catch(error => {
            if (error.name != "NavigationDuplicated") {
                throw error;
            }
          })
        } else {
          throw new Error("Error")
        }
      })
      .catch( error => {
          console.log(error)
      })
    },
    agentDashboard() {
      this.$router.push('/dashboard/agent')
    },
    // resetUserActivityTimeout() {
    //   console.log('reset activity timer')
    //   clearTimeout(this.activityTimer);
    //   this.activityTimer = setTimeout(() => {
    //     this.logout()
    //   }, 300000);
    // },
    // userActivityThrottler() {
    //   if (!this.activityTimerThrottler) {
    //     console.log('setting throttler')
    //     this.activityTimerThrottler = setTimeout(() => {
    //       this.resetUserActivityTimeout();

    //       clearTimeout(this.activityTimerThrottler);
    //       this.activityTimerThrottler = null;
    //     }, 60000);
    //   }
    // },
    // activateActivityTracker() {
    //   window.addEventListener("mousemove", this.userActivityThrottler)
    //   window.addEventListener("scroll", this.userActivityThrottler)
    //   window.addEventListener("keydown", this.userActivityThrottler)
    //   window.addEventListener("resize", this.userActivityThrottler)
    // }
  },
  created() {
   this.getAllData()
   console.log('created recruiter dashboard')
  },
  // mounted() {
  //   this.activateActivityTracker()
  // },
  // beforeDestroy() {
  //   console.log('destoying component')
  //   window.removeEventListener("mousemove", this.userActivityThrottler)
  //   window.removeEventListener("scroll", this.userActivityThrottler)
  //   window.removeEventListener("keydown", this.userActivityThrottler)
  //   window.removeEventListener("resize", this.userActivityThrottler)
  //   clearTimeout(this.activityTimer)
  //   clearTimeout(this.activityTimerThrottler)
  //   this.activityTimer = null
  //   this.activityTimerThrottler = null
  // },
  beforeDestroy() {

    console.log('destroying component')
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Recruiter" : "Edit Recruiter Record";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
};
</script>

<style scoped>
.details-ul {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding-left: 12px;
}

#dashboard-link {
  text-decoration: none;
  color: #1976d2;
}

#dashboard-link:hover {
  filter: brightness(115%);
}

.light-theme {
  color: rgba(0, 0, 0, 0.6);
}

input[type="date"]::-webkit-calendar-picker-indicator {
  margin-left: -25px !important;
}
</style>
