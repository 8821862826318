import Vue from "vue";
import VueRouter from "vue-router";
import AdminLandingPage from "@/components/AdminLandingPage";
import AdminForgotPassword from "@/components/AdminForgotPassword";
import AdminNewPassword from "@/components/AdminNewPassword";
import AdminDashboard from "@/components/AdminDashboard";
import AdminDashboardAgent from "@/components/AdminDashboardAgent";
import AgentOnboardForm from "@/components/AgentOnboardForm";
import OnboardFormSuccess from "@/components/OnboardFormSuccess";
import OnboardFormFail from "@/components/OnboardFormFail";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "AdminLandingPage",
    component: AdminLandingPage,
  },
  {
    path: "/forgot",
    name: "AdminForgotPassword",
    component: AdminForgotPassword,
  },
  {
    path: "/new-password",
    name: "AdminNewPassword",
    component: AdminNewPassword,
  },
  {
    path: "/dashboard",
    name: "AdminDashboard",
    component: AdminDashboard,
  },
  {
    path: "/dashboard/agent",
    name: "AdminDashboardAgent",
    component: AdminDashboardAgent,
  },
  {
    path: "/onboard",
    name: "AgentOnboardForm",
    component: AgentOnboardForm,
  },
  {
    path: "/onboard/success",
    name: "OnboardFormSuccess",
    component: OnboardFormSuccess,
  },
  {
    path: "/onboard/duplicate-submission",
    name: "OnboardFormFail",
    component: OnboardFormFail,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
