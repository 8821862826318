import axios from 'axios'

export default (contentType="application/json") => {
  return axios.create({
    withCredentials: true,
    credentials: 'include',
    baseUrl: `https://recruit.healthdistributionagency.com`,
    headers: {
      'Content-Type': contentType
    }
  })
}
