<template>
  <base-container-with-bg>
    <div
      class="row d-flex justify-content-center align-items-center full-height"
    >
      <div
        class="col-12 col-md-8 col-lg-8 col-xl-6 d-flex justify-content-center w-75 form-partent-col"
      >
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
          <BForm
            @submit.prevent="handleSubmit(loginPost)"
            class="border form-p shadow agent-form text-center"
          >
            <div class="row text-center w-50 mx-auto">
              <h5 class="mx-auto">Agent Onboarding</h5>
              <div class="mx-auto col-12 w-50">
                <validation-provider
                  name="email"
                  :rules="{ required: true, email: true }"
                  v-slot="validationContext"
                >
                  <BFormInput
                    id="email_input"
                    v-model="creds.email"
                    type="email"
                    :state="getValidationState(validationContext)"
                    placeholder="Email"
                    aria-describedby="email_input_live_feedback"
                    autocomplete="off"
                  ></BFormInput>
                  <BFormInvalidFeedback id="email_input_live_feedback">{{
                    validationContext.errors[0]
                  }}</BFormInvalidFeedback>
                </validation-provider>
              </div>
              <div class="col-12 mx-auto w-50">
                <validation-provider
                  name="password"
                  :rules="{ required: true }"
                  v-slot="validationContext"
                >
                  <BFormInput
                    id="password_input"
                    v-model="creds.password"
                    type="password"
                    :state="getValidationState(validationContext)"
                    placeholder="Password"
                    aria-describedby="password_input_live_feedback"
                  ></BFormInput>
                  <BFormInvalidFeedback
                    id="password_input_live_feedback"
                    >{{ validationContext.errors[0] }}</BFormInvalidFeedback
                  >
                </validation-provider>
              </div>
              <div class="col-md-12 d-flex justify-content-center mx-auto">
                <BButton class="btn-color btn" type="submit" variant="primary"
                  >Enter Portal</BButton
                >
              </div>
              <div class="col-md-12 d-flex justify-content-center mx-auto">
                <a class="mx-auto" @click="forgot()">Forgot your password?</a>
              </div>
            </div>
          </BForm>
        </validation-observer>
      </div>
    </div>
      <BModal ok-only id="access-denied" class="btn-color" title="Access Denied">
        <p class="my-4">Invalid username or password value</p>
        <template #modal-footer>
          <button v-b-modal.modal-close_visit @click="$bvModal.hide('access-denied')" class="btn btn-danger btn-md m-1">Close</button>
        </template>
      </BModal>
      <BModal ok-only id="server-error" class="btn-color" title="Error">
        <p class="my-4">Internal server error, could not connect</p>
        <template #modal-footer>
          <button v-b-modal.modal-close_visit @click="$bvModal.hide('server-error')" class="btn btn-danger btn-md m-1">Close</button>
        </template>
      </BModal>
  </base-container-with-bg>
</template>

<script>
import AuthCheck from "@/services/AuthCheck"
import AgentPortalAuth from "@/services/AgentPortalAuth"
import BaseContainerWithBg from "./BaseContainerWithBg.vue"
import { BForm, BFormInput, BFormInvalidFeedback, BButton, BModal, VBModal } from 'bootstrap-vue'

export default {
  components: {
    BaseContainerWithBg,
    BForm,
    BFormInput,
    BFormInvalidFeedback, 
    BButton,
    BModal
  },
  directives: {
    'b-modal': VBModal

  },
  data() {
    return {
      creds: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    loginPost() {
      AgentPortalAuth.submit({
        username: this.creds.email,
        password: this.creds.password,
      })
        .then((resp) => {
          if (resp.status === 200) {
            this.$router.push("/dashboard");
          } else {
            throw new Error("Error");
          }
        })
        .catch((error) => {
          console.log(error.response.status)
          if (error.response.status == 401) {
            this.$bvModal.show("access-denied")
            this.creds.email = this.creds.password = ''
            this.$refs.observer.reset()
          }
          else {
            this.$bvModal.show("server-error")
            this.creds.email = this.creds.password = ''
            this.$refs.observer.reset()
          }
          console.log(error);
        });
    },
    forgot() {
      this.$router.push("/forgot")
    }
  },
  beforeCreate() {
    AuthCheck.check()
    .then((resp) => {
      if (resp.status === 200) {
        this.$router.push("/dashboard")
      } 
      else {
        throw new Error("Error")
      }
    })
    .catch((error) => {
      console.log(error)
    })

  }
};
</script>

<style scoped>
h5 {
  color: #333;
}

.m-sides-30 {
  margin-left: 30px !important;
  margin-right: 30px !important;
}

.full-height {
  height: 100vh;
}

.form-partent-col {
  max-width: 600px;
}

.form-fit {
  width: fit-content;
}

.form-p {
  padding: 3rem 0rem;
}

.agent-form {
  background-color: #fafafa;
  border-radius: 10px;
}

.btn-color {
  background-color: #1778a7;
  color: #fff;
  border: none;
}

button {
  color: #fff;
}

.btn:hover {
  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
}
</style>
