<template>
  <base-container-with-bg class="d-flex align-items-center">
    <BCard
      header="Duplicate Submission"
      header-tag="header"
      title="Agent Already Registered"
      class="b_card"
    >
      <BCardText>
        It appears that this agent is already registered in our system.
      </BCardText>
      <BCardText>
        One cause of this error could be that the email you provided is already
        associated with an agent in our records. Make sure the email you are
        entering is an email unique to you.
      </BCardText>
      <BCardText>
        If you believe this is a mistake please reach out to your recruiter.
      </BCardText>
      <BCardText> You may now close out this tab </BCardText>
    </BCard>
  </base-container-with-bg>
</template>

<script>
import BaseContainerWithBg from "./BaseContainerWithBg.vue";
import { BCard, BCardText } from 'bootstrap-vue'

export default {
  components: {
    BaseContainerWithBg,
    BCard,
    BCardText
  },
};
</script>

<style scoped>
.b_card {
  display: flex;
  align-self: center;
  max-width: 50%;
  margin: auto;
}

.card-header,
.card-text {
  text-align: start;
}

@media only screen and (max-width: 47.9375em) {
  .b_card {
    display: flex;
    align-self: center;
    max-width: 100%;
    width: 100%;
  }
}
</style>
