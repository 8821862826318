<template>
  <div class="container-fluid full-height bg">
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.full-height {
  height: 100vh;
}
.bg {
  background: url("./../assets/gradienta-UF_wwDxI6uk-unsplash.jpeg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
}
</style>
