<template>
  <base-container-with-bg class="d-flex align-items-center">
    <BCard
      v-if="this.$store.state.first_name.agent"
      header="Submission Successful"
      header-tag="header"
      :title="`Thank you, ${this.$store.state.first_name.agent}!`"
      class="b_card"
    >
      <BCardText
        >We will begin processing your application as soon as possible. If we
        have any questions about your application we will contact you at the
        email you provided in the form.<br /><br />
        You may now close out this tab and we hope you have a wonderful day :)
      </BCardText>
    </BCard>
    <BCard
      v-else
      header="Submission Successful"
      header-tag="header"
      :title="`Thank you!`"
      class="b_card"
    >
      <BCardText
        >We will begin processing your application as soon as possible. If we
        have any questions about your application we will contact you at the
        email you provided in the form.<br /><br />
        You may now close out this tab and we hope you have a wonderful day :)
      </BCardText>
    </BCard>
  </base-container-with-bg>
</template>

<script>
import BaseContainerWithBg from "./BaseContainerWithBg.vue";
import { BCard, BCardText } from 'bootstrap-vue'

export default {
  components: {
    BaseContainerWithBg,
    BCard,
    BCardText
  },
};
</script>

<style scoped>
.b_card {
  display: flex;
  align-self: center;
  max-width: 50%;
  margin: auto;
}

.card-header,
.card-text {
  text-align: start;
}
@media only screen and (max-width: 47.9375em) {
  .b_card {
    display: flex;
    align-self: center;
    max-width: 100%;
    width: 100%;
  }
}
</style>
