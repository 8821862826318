<template>
  <div class="container-fluid">
    <HeaderLogo />
    <div class="container">
      <div class="row">
        <div>
          <validation-observer
            ref="observer"
            v-slot="{ handleSubmit, invalid }"
          >
            <BForm
              @submit.prevent="handleSubmit(onSubmit)"
              class="mt-5 border p-5 shadow agent-form mx-auto"
            >
              <h4 class="mb-4 text-secondary">Agent Information</h4>
              <div class="row">
                <div class="mb-3 col-md-6">
                  <validation-provider
                    name="first name"
                    :rules="{required: true, max: 50, regex: /^(?=.{1,50}$)[a-zA-Z]+(?:[-'\s][a-zA-Z]+)*$/}"
                    v-slot="validationContext"
                  >
                    <BFormGroup
                      id="first_name_group"
                      label="First Name"
                      label-for="first_name_input"
                    >
                      <BFormInput
                        id="first_name_input"
                        v-model="form.agent.first_name"
                        :state="getValidationState(validationContext)"
                        type="text"
                        placeholder="Enter First Name"
                        aria-describedby="first_name_input_live_feedback"
                        autocomplete="new-password"
                      ></BFormInput>
                      <BFormInvalidFeedback
                        id="first_name_input_live_feedback"
                        >{{
                          validationContext.errors[0]
                        }}</BFormInvalidFeedback
                      >
                    </BFormGroup>
                  </validation-provider>
                </div>
                <div class="mb-3 col-md-6">
                  <validation-provider
                    name="last name"
                    :rules="{required: true, max: 50, regex: /^(?=.{1,50}$)[a-zA-Z]+(?:[-'\s][a-zA-Z]+)*$/}"
                    v-slot="validationContext"
                  >
                    <BFormGroup
                      id="last_name_group"
                      label="Last Name"
                      label-for="last_name_input"
                    >
                      <BFormInput
                        id="last_name_input"
                        v-model="form.agent.last_name"
                        :state="getValidationState(validationContext)"
                        type="text"
                        placeholder="Enter Last Name"
                        aria-describedby="last_name_input_live_feedback"
                        autocomplete="new-password"
                      ></BFormInput>
                      <BFormInvalidFeedback
                        id="last_name_input_live_feedback"
                        >{{
                          validationContext.errors[0]
                        }}</BFormInvalidFeedback
                      >
                    </BFormGroup>
                  </validation-provider>
                </div>
                <div class="mb-3 col-md-6">
                  <validation-provider
                    name="agent npn"
                    :rules="{ required: true, numeric: true, alpha: false, max: 10 }"
                    v-slot="validationContext"
                  >
                    <BFormGroup
                      id="agent_npn_group"
                      label="Agent NPN"
                      label-for="agent_npn_input"
                    >
                      <BFormInput
                        id="agent_npn_input"
                        v-model="form.agent.npn"
                        type="text"
                        :state="getValidationState(validationContext)"
                        placeholder="Enter Agent NPN"
                        maxlength="10"
                        aria-describedby="agent_npn_input_live_feedback"
                      ></BFormInput>
                      <BFormInvalidFeedback
                        id="agent_npn_input_live_feedback"
                        >{{
                          validationContext.errors[0]
                        }}</BFormInvalidFeedback
                      >
                    </BFormGroup>
                  </validation-provider>
                </div>
                <div class="mb-3 col-md-6">
                  <validation-provider
                    name="social security number"
                    :rules="{ required: true, min: 11, regex: /^[0-9-]*$/, max: 11 }"
                    v-slot="validationContext"
                  >
                    <BFormGroup
                      id="ssn_group"
                      label="SSN"
                      label-for="ssn_input"
                    >
                      <BFormInput
                        id="ssn_input"
                        v-model="form.agent.ssn"
                        type="text"
                        :state="getValidationState(validationContext)"
                        v-mask="'###-##-####'"
                        placeholder="###-##-####"
                        maxlength="11"
                        aria-describedby="ssn_input_live_feedback"
                      ></BFormInput>
                      <BFormInvalidFeedback id="ssn_input_live_feedback">{{
                        validationContext.errors[0]
                      }}</BFormInvalidFeedback>
                    </BFormGroup>
                  </validation-provider>
                </div>
                <div class="mb-3 col-md-12">
                  <validation-provider
                    name="date of birth"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <BFormGroup
                      id="dob_group"
                      label="Date of Birth"
                      label-for="dob_input"
                    >
                      <BFormInput
                        id="dob_input"
                        v-model="form.agent.dob"
                        type="date"
                        :state="getValidationState(validationContext)"
                        placeholder="Enter Date of Birth"
                        max="9999-12-31"
                        aria-describedby="dob_input_live_feedback"
                      ></BFormInput>
                      <BFormInvalidFeedback id="dob_input_live_feedback">{{
                        validationContext.errors[0]
                      }}</BFormInvalidFeedback>
                    </BFormGroup>
                  </validation-provider>
                </div>
                <div class="mb-3 col-md-12">
                  <validation-provider
                    name="email"
                    :rules="{ required: true, email: true, max: 250 }"
                    v-slot="validationContext"
                  >
                    <BFormGroup
                      id="email_group"
                      label="Agent Email"
                      label-for="email_input"
                    >
                      <BFormInput
                        id="email_input"
                        v-model="form.agent.email.email"
                        type="email"
                        :state="getValidationState(validationContext)"
                        placeholder="Enter Agent Email"
                        aria-describedby="email_input_live_feedback"
                        autocomplete="off"
                      ></BFormInput>
                      <BFormInvalidFeedback id="email_input_live_feedback">{{
                        validationContext.errors[0]
                      }}</BFormInvalidFeedback>
                    </BFormGroup>
                  </validation-provider>
                </div>
              </div>
              <!-- <h4 class="mb-4 text-secondary">Agency Information</h4>
              <div class="row">
                <div class="col-md-6 mb-3">
                  <validation-provider
                    name="agency name"
                    :rules="{ required: true, max: 100, regex: /^(?=.{1,100}$)[a-zA-Z]+(?:[-'\s][a-zA-Z]+)*$/}"
                    v-slot="validationContext"
                  >
                    <BFormGroup
                      id="agency_name_group"
                      label="Agency"
                      label-for="agency_name_input"
                    >
                      <BFormInput
                        id="agency_name_input"
                        v-model="form.agency.name"
                        type="text"
                        :state="getValidationState(validationContext)"
                        placeholder="Enter Agency Name"
                        aria-describedby="agency_name_input_live_feedback"
                      ></BFormInput>
                      <BFormInvalidFeedback
                        id="agency_name_input_live_feedback"
                        >{{
                          validationContext.errors[0]
                        }}</BFormInvalidFeedback
                      >
                    </BFormGroup>
                  </validation-provider>
                </div>
                <div class="col-md-6 mb-3">
                  <validation-provider
                    name="agency npn"
                    :rules="{ required: true, numeric: true, alpha: false, max: 10 }"
                    v-slot="validationContext"
                  >
                    <BFormGroup
                      id="agency_npn_group"
                      label="Agency NPN"
                      label-for="agency_npn_input"
                    >
                      <BFormInput
                        id="agency_npn_input"
                        v-model="form.agency.npn"
                        type="text"
                        :state="getValidationState(validationContext)"
                        placeholder="Enter Agency NPN"
                        maxlength="10"
                        aria-describedby="agency_npn_input_live_feedback"
                      ></BFormInput>
                      <BFormInvalidFeedback
                        id="agency_npn_input_live_feedback"
                        >{{
                          validationContext.errors[0]
                        }}</BFormInvalidFeedback
                      >
                    </BFormGroup>
                  </validation-provider>
                </div>
              </div> -->
              <div class="row mt-2">
                <div class="col-md-12">
                  <p class="text-center mt-4 mb-3 text-secondary">
                    Before submitting, please verify that all information above
                    is correct. Providing accurate information will allow for a
                    quicker onboarding process.
                  </p>
                </div>
                <div class="col-md-12 d-flex justify-content-center">
                  <label class="text-center">
                    <input
                      type="checkbox"
                      class="me-2 text-center"
                      v-model="checked"
                      name="verification-cb"
                      id="verification-cb"
                      autocomplete="off"
                    />
                    I confirm that my information is accurate
                  </label>
                </div>
                <div class="col-md-12 d-flex justify-content-center mt-3">
                  <BButton
                    type="submit"
                    variant="primary"
                    class="btn btn-text-color"
                    :disabled="!checked || invalid"
                    >Submit Form</BButton
                  >
                </div>
              </div>
            </BForm>
          </validation-observer>
        </div>
      </div>
    </div>
    <WebsiteFooter />
  </div>
</template>

<script>
import HeaderLogo from "./HeaderLogo"
import AgentPortalForm from "@/services/AgentPortalForm"
import { BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton } from 'bootstrap-vue'

export default {
  name: "AgentOnboardForm",
  components: {
    HeaderLogo,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
  },
  data() {
    return {
      form: {
        agent: {
          first_name: "",
          last_name: "",
          ssn: "",
          npn: "",
          dob: "",
          email: {
            email: "",
            confirm_email: "",
          },
        },
        // agency: {
        //   name: "",
        //   npn: "",
        // },
      },
      checked: false,
      canPaste: "return false",
    };
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    onSubmit() {
      this.$store.commit("addAgentFirstName", this.form.agent.first_name);

      AgentPortalForm.submit({
        AgentFirstName: this.form.agent.first_name,
        AgentLastName: this.form.agent.last_name,
        AgentSSN: this.form.agent.ssn,
        AgentNPN: this.form.agent.npn,
        AgentDOB: this.form.agent.dob,
        AgentEmail: this.form.agent.email.email,
        // AgencyName: this.form.agency.name,
        // AgencyNPN: this.form.agency.npn,
        RecruiterId: this.$route.query.recruiter_id,
      })
        .then((resp) => {
          if (resp.status === 200) {
            this.$router.push("/onboard/success");
          } else {
            // throw error and go to catch block
            throw new Error("Error");
          }
        })
        .catch((error) => {
          if (error.response.status === 409) {
            this.$router.push("/onboard/duplicate-submission");
          } else {
            alert("There seems to be a problem with our system, we were unable to process the submission. Please try again later.");
            console.log(error);
          }
        });
    },
  },
};
</script>

<style scoped>
.label-margin {
  margin-bottom: 0.5rem;
}

.agent-form {
  background-color: #fafafa;
  max-width: 75%;
}

.btn-text-color {
  color: #fff;
  border: none;
}

.btn:hover {
  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
}

@media only screen and (max-width: 47.9375em) {
  .agent-form {
    max-width: 100%;
    width: 100%;
  }
}
</style>
