<template>
  <base-container-with-bg>
    <div
      class="row d-flex justify-content-center align-items-center full-height"
    >
      <div
        class="col-12 col-md-8 col-lg-8 col-xl-6 d-flex justify-content-center w-75 form-partent-col"
      >
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
          <BForm
            @submit.prevent="handleSubmit(newPasswordPost)"
            class="border form-p shadow agent-form text-center"
          >
            <div class="row text-center w-50 mx-auto">
              <h5 class="mx-auto">New Password</h5>
              <div class="col-12 mx-auto w-50">
                <validation-provider
                  name="password"
                  vid="password"
                  :rules="{ required: true, min: 10, max: 100, verify_password: true }"
                  v-slot="validationContext"
                >
                  <BFormInput
                    id="password_input"
                    v-model="creds.password"
                    type="password"
                    :state="getValidationState(validationContext)"
                    placeholder="New Password"
                    aria-describedby="password_input_live_feedback"
                  ></BFormInput>
                  <BFormInvalidFeedback id="password_input_live_feedback">{{
                    validationContext.errors[0]
                  }}</BFormInvalidFeedback>
                </validation-provider>
              </div>
              <div class="col-12 mx-auto w-50">
                <validation-provider
                  name="confirm password"
                  rules="required|confirmed:password"
                  v-slot="validationContext"
                >
                  <BFormInput
                    id="confirm_password_input"
                    v-model="creds.passwordConfirm"
                    type="password"
                    :state="getValidationState(validationContext)"
                    placeholder="Confirm New Password"
                    aria-describedby="confirm_password_input_live_feedback"
                  ></BFormInput>
                  <BFormInvalidFeedback id="confirm_password_input_live_feedback">{{
                    validationContext.errors[0]
                  }}</BFormInvalidFeedback>
                </validation-provider>
              </div>
              <div class="col-md-12 d-flex">
                <BButton
                  class="btn-color btn mr-auto w-50"
                  type="submit"
                  variant="primary"
                  >Submit</BButton
                >
                <a class="align-self-end" @click="home()">Go to Login</a>
              </div>
            </div>
          </BForm>
        </validation-observer>
      </div>
    </div>
    <BModal ok-only id="server-error" class="btn-color" title="Error">
      <p class="my-4">Internal server error, could not connect</p>
      <template #modal-footer>
        <button
          v-b-modal.modal-close_visit
          @click="$bvModal.hide('server-error')"
          class="btn btn-danger btn-md m-1"
        >
          Close
        </button>
      </template>
    </BModal>
    <BModal ok-only id="same-pw" class="btn-color" title="Warning">
      <p class="my-4">Your password may not be your previous password, please select a new password</p>
      <template #modal-footer>
        <button
          v-b-modal.modal-close_visit
          @click="$bvModal.hide('same-pw')"
          class="btn btn-danger btn-md m-1"
        >
          Close
        </button>
      </template>
    </BModal>
    <BModal ok-only @hide="onHide" id="new-pass-success" class="btn-color" title="Success">
      <p class="my-4">
        Your password has been changed 
      </p>
      <template #modal-footer>
        <button
          v-b-modal.modal-close_visit
          @click="$bvModal.hide('new-pass-success')"
          class="btn btn-danger btn-md m-1"
        >
          Close
        </button>
      </template>
    </BModal>
  </base-container-with-bg>
</template>

<script>
import BaseContainerWithBg from "./BaseContainerWithBg.vue"
import PasswordReset from "@/services/PasswordReset"
import { BForm, BFormInput, BFormInvalidFeedback, BButton, BModal } from 'bootstrap-vue'

export default {
  components: {
    BaseContainerWithBg,
    BForm,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BModal
  },
  data() {
    return {
      creds: {
        password: "",
        passwordConfirm: ""
      },
    };
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    newPasswordPost() {
      PasswordReset.reset({
        password: this.creds.password,
        userId: this.$route.query.user_id
      })
        .then((resp) => {
          if (resp.status === 200) {
            console.log(resp.data);
            this.creds.passwordConfirm = this.creds.password = "";
            this.$refs.observer.reset();
            this.$bvModal.show("new-pass-success");
          } else {
            throw new Error("Error");
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          if (error.response.status === 409) {
            this.$bvModal.show("same-pw");
            this.creds.passwordConfirm = this.creds.password = "";
            this.$refs.observer.reset();
          }
          else {
            this.$bvModal.show("server-error");
            this.$refs.observer.reset();
          }
          console.log(error);
        });
    },
    home() {
      this.$router.push("/");
    },
    onHide() {
      this.home()
    },
  },
};
</script>

<style scoped>
h5 {
  color: #333;
}

.m-sides-30 {
  margin-left: 30px !important;
  margin-right: 30px !important;
}

.full-height {
  height: 100vh;
}

.form-partent-col {
  max-width: 600px;
}

.form-fit {
  width: fit-content;
}

.form-p {
  padding: 3rem 0rem;
}

.agent-form {
  background-color: #fafafa;
  border-radius: 10px;
}

.btn-color {
  background-color: #1778a7;
  color: #fff;
  border: none;
}

button {
  color: #fff;
}

.btn:hover {
  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
}
</style>
