import Api from '@/services/Api'

export default {
    getAllRecruiterData () {
      return Api().get('/dashboard/data/recruiter')
    },
    postRecruiterData (recruiterData) {
      return Api().post('/dashboard/data/create', recruiterData)
    },
    updateRecruiterData (recruiterData) {
      return Api().post('/dashboard/data/recruiter/update', recruiterData)
    },
    deleteRecruiterData (recruiterData) {
      return Api().post('/dashboard/data/delete', recruiterData)
    },
}