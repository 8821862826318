<template>
  <div class="container mt-5">
    <div class="row">
      <div class="col-md-12 d-flex justify-content-center mt-3">
        <span class="text-muted"
          >Copyright &#169; {{ currYear }} Health Distribution Agency
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    currYear() {
      return new Date().getFullYear();
    },
  },
};
</script>
