import Api from '@/services/Api'

export default {
    getAllAgentData (params) {
      return Api().get('/dashboard/data/agent', params)
    },
    updateAgentData (agentData) {
      return Api().post('/dashboard/data/agent/update', agentData)
    },
    uploadUpdatedAgentData (agentData) {
      return Api('multipart/form-data').post('/dashboard/data/agent/file-upload/update', agentData)
    },
    uploadCshAgentData (agentData) {
      return Api('multipart/form-data').post('/dashboard/data/agent/file-upload/csh', agentData)
    },
    deleteAgentData (agentData) {
      return Api().post('/dashboard/data/delete', agentData)
    },
}