import "@babel/polyfill"
import "mutationobserver-shim"
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { ModalPlugin, TooltipPlugin } from 'bootstrap-vue'
import Vue from "vue"
import VueMask from "v-mask"
import App from "./App.vue"
import router from "./router"
import Vuex from "vuex"
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize,
} from "vee-validate"
import en from "vee-validate/dist/locale/en.json"
import * as rules from "vee-validate/dist/rules"
import WebsiteFooter from "./components/WebsiteFooter.vue"
import vuetify from './plugins/vuetify'

Vue.use(VueMask)
Vue.use(Vuex)
Vue.use(ModalPlugin)
Vue.use(TooltipPlugin)

Vue.component("ValidationObserver", ValidationObserver)
Vue.component("ValidationProvider", ValidationProvider)
Vue.component("WebsiteFooter", WebsiteFooter)

Vue.config.productionTip = false

// VeeValidate rules and localization
Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});
localize("en", en);

extend('verify_password', {
  message: field => `The ${field} field must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number, and one special character (! @ # $ % ^ & *)`,
  validate: value => {
      var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{10,})");
      return strongRegex.test(value);
  }
});

// Vuex
const store = new Vuex.Store({
  state() {
    return {
      isLoggedIn: {
        admin: false,
      },
      first_name: {
        agent: "",
        admin: "",
      },
      baseUrl: "https://recruit.healthdistributionagency.com"
    };
  },
  mutations: {
    changeAdminAuthState(state) {
      state.isLoggedIn.admin = !state.isLoggedIn.admin;
    },
    addAgentFirstName(state, first_name) {
      state.first_name.agent = first_name;
    },
  },
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount("#app");
